<template>
  <div class="mcp-choose-industries">
    <div class="mcp-partials">
      <div class="mcp-ci-wrapper mcp-ci-shadow">
        <DgInput
          placeholder="Filter by Audit Processes/Question groups e.g. 4.1 Patientenver"
          v-model="searchQuery"
          @change="searchList"
        />
        <DgAccordion class="dg-mcp-accordion dg-scrollbar">
          <DgAccordionItem v-for="(industry, idx) in modifedList" :key="idx" :id="idx" :title="industry.name">
            <DgAccordionActions slot="actions">
              <span
                class="mcp-add-all"
                @click="addAllAuditProcesses(industry.audit_processes, industry.name)"
                v-if="industry && industry.name && !(countChecked(industry.name) === industry.audit_processes.length)"
                >Add all</span
              >
              <span class="mcp-count-checked">
                <i class="dgi dgi-i-check" />
                {{ countChecked(industry.name) }}
              </span>
            </DgAccordionActions>
            <DgAccordionContent>
              <ul class="sub-industries-list">
                <li v-for="(audit_process, idx) in industry.audit_processes" :key="idx">
                  <div class="sub-industries-item">
                    <span>{{ audit_process.name }}</span>
                    <DgButton
                      :textIcon="isAuditProcessChosen(audit_process.id) ? 'dgi-check' : 'dgi-d-add'"
                      size="x-small"
                      :hover="false"
                      :type="isAuditProcessChosen(audit_process.id) ? 'success' : 'primary'"
                      @click="toggleAuditProcess(audit_process, industry.name)"
                    >
                      <span v-if="!isAuditProcessChosen(audit_process.id)">Add</span>
                      <span v-else>Added</span>
                    </DgButton>
                  </div>
                  <ul v-if="audit_process.question_groups">
                    <li v-for="(question_group, idx) in audit_process.question_groups" :key="idx">
                      <span>{{ question_group.name }}</span>
                      <DgButton
                        :textIcon="isQuestionGroupChosen(audit_process.id, question_group) ? 'dgi-check' : 'dgi-d-add'"
                        size="x-small"
                        :hover="false"
                        :type="isQuestionGroupChosen(audit_process.id, question_group) ? 'success' : 'primary'"
                        @click="toggleQuestionGroup(industry.name, audit_process, question_group)"
                      >
                        <span v-if="!isQuestionGroupChosen(audit_process.id, question_group)">Add</span>
                        <span v-else>Added</span>
                      </DgButton>
                    </li>
                  </ul>
                </li>
              </ul>
            </DgAccordionContent>
          </DgAccordionItem>
        </DgAccordion>
      </div>
      <DgMcpBasket class="mcp-basket dg-scrollbar" :list.sync="basketList" />
    </div>
    <DgMcpModal
      v-if="showModal"
      :list="basketList"
      :showModal="showModal"
      @closeModal="showModal = false"
      @onReject="showModal = false"
      @onAccept="submitData(basketList)"
      :header="`Confirm the new processes for ${mcpCustomer.name}:`"
      :submiting.sync="showLoader"
      primaryButton="Confirm & Save"
      secondaryButton="Back to Edit"
    />
    <DgActionBar>
      <DgButtonGroup>
        <DgButton type="secondary" @click="previous()">Cancel & Go Back</DgButton>
        <DgButton @click="next()">Submit</DgButton>
      </DgButtonGroup>
    </DgActionBar>
  </div>
</template>

<script>
import { Structural, Basic, Form, MCP } from "@/dgui-customer-components/";
import { mapGetters } from "vuex";

export default {
  name: "MCP-ChooseIndustries",
  components: {
    DgAccordion: Structural.DgAccordion.DgAccordion,
    DgAccordionItem: Structural.DgAccordion.DgAccordionItem,
    DgAccordionActions: Structural.DgAccordion.DgAccordionActions,
    DgAccordionContent: Structural.DgAccordion.DgAccordionContent,
    DgActionBar: Structural.DgActionBar,
    DgButton: Basic.DgButton,
    DgButtonGroup: Basic.DgButtonGroup,
    DgMcpBasket: MCP.DgMcpBasket,
    DgMcpModal: MCP.DgMcpModal,
    DgInput: Form.DgInput,
    DgButton: Basic.DgButton,
  },
  data() {
    return {
      searchQuery: null,
      basketList: [],
      showModal: false,
      showLoader: false,
    };
  },
  created() {
    if (this.mcpActiveStep === 1 || this.mcpchosenIndustries.length === 0) {
      this.$dgToast.show({
        type: "warning",
        title: "Choose an industry",
        content: "Please choose at least one industry and proceed to the next step",
        closeTimeout: 4000,
      });
      this.$router.push({ name: "MCPIndustries" });
    }
  },
  computed: {
    ...mapGetters({
      mcpCustomer: "getMcpCustomer",
      mcpList: "getMcpList",
      mcpActiveStep: "getMcpActiveStep",
      mcpchosenIndustries: "getMcpchosenIndustries",
      mcpAuditProcessById: "getMcpAuditProcessById",
      mcpQuestionGroupById: "getMcpQuestionGroupById",
      mcpChosenByQuery: "getMcpChosenByQuery",
    }),
    modifedList() {
      if (this.searchQuery && this.searchQuery.length > 0) {
        return this.mcpChosenByQuery(this.searchQuery);
      }
      return this.mcpchosenIndustries;
    },
    countChecked() {
      return industry => this.basketList.filter(entry => entry.industry === industry).length;
    },
  },
  methods: {
    searchList(value) {
      this.searchQuery = value;
    },
    isAuditProcessChosen(auditId) {
      const checkAudit = this.basketList.find(audit => audit.id === auditId);

      if (checkAudit === undefined) {
        return false;
      } else if (checkAudit.question_groups.length === 0) {
        return this.basketList.splice(
          this.basketList.findIndex(auditProcess => auditProcess.id === checkAudit.id),
          1
        );
      }

      return true;
    },
    isQuestionGroupChosen(auditId, questionGroup) {
      const checkAudit = this.basketList.find(audit => audit.id === auditId);

      if (checkAudit !== undefined) {
        const checkQuestion = checkAudit.question_groups.find(question => question.id === questionGroup.id);

        if (checkQuestion === undefined) {
          return false;
        }
        return true;
      }

      return false;
    },
    toggleAuditProcess(audit, industryName) {
      if (this.isAuditProcessChosen(audit.id)) {
        return this.basketList.splice(
          this.basketList.findIndex(auditProcess => auditProcess.id === audit.id),
          1
        );
      }
      this.basketList.push({
        industry: industryName,
        ...audit,
      });
    },
    toggleQuestionGroup(industryName, auditProcess, questionGroup) {
      let checkAudit = this.basketList.find(audit => audit.id === auditProcess.id);

      if (checkAudit !== undefined && this.isQuestionGroupChosen(auditProcess.id, questionGroup)) {
        this.basketList.forEach(industry => {
          if (industry.id === checkAudit.id) {
            industry.question_groups = industry.question_groups.filter(question => question.id !== questionGroup.id);
          }
        });
      } else if (checkAudit !== undefined && !this.isQuestionGroupChosen(auditProcess.id, questionGroup)) {
        this.basketList.forEach(industry => {
          if (industry.id === checkAudit.id) {
            industry.question_groups.push({
              id: questionGroup.id,
              name: questionGroup.name,
            });
          }
        });
      } else if (checkAudit === undefined && !this.isQuestionGroupChosen(auditProcess.id, questionGroup)) {
        this.basketList.push({
          industry: industryName,
          id: auditProcess.id,
          name: auditProcess.name,
          question_groups: [
            {
              id: questionGroup.id,
              name: questionGroup.name,
            },
          ],
        });
      }
    },
    addAllAuditProcesses(auditProcesses, industryName) {
      auditProcesses.map(auditProcess => {
        const audit = {
          industry: industryName,
          ...auditProcess,
        };
        if (this.basketList.indexOf(audit) === -1) {
          this.basketList.push(audit);
        }
      });
    },
    previous() {
      if (this.basketList.length > 0) {
        return this.$dgToast.show({
          type: "confirm",
          title: "Data will not be saved",
          content: "Selected processes will be lost, are you sure you want to go back to industry selection?",
          confirmButtonLabel: "Yes, go back",
          rejectButtonLabel: "Cancel",
          onAccept: () => {
            this.$router.push({ name: "MCPIndustries" });
            this.$store.dispatch("setMcpActiveStep", 1);
          },
        });
      }
      this.$router.push({ name: "MCPIndustries" });
      return this.$store.dispatch("setMcpActiveStep", 1);
    },
    next() {
      if (this.basketList.length > 0) {
        return (this.showModal = true);
      } else {
        return this.$dgToast.show({
          type: "warning",
          title: "Choose an industry",
          content: "You need to choose at least one industry before submiting",
          closeTimeout: 4000,
        });
      }
    },
    submitData(mappedIndustry) {
      let questionGroups = [];

      mappedIndustry.filter((auditProcess, apIndex) => {
        auditProcess.question_groups.map((questionGroup, qgIndex) => {
          questionGroups.push({
            question_group_id: questionGroup.id,
            index: apIndex + 1 + "." + (qgIndex + 1),
          });
        });
      });

      let submitData = {
        customer: this.mcpCustomer.id,
        customer_industry_question_groups_attributes: [...questionGroups],
      };
      this.$http
        .post(`/api1/v2/customers/${this.mcpCustomer.id}/customer_industry`, submitData)
        .then(response => {
          this.showLoader = true;
          window.location.replace("/redirect");
        })
        .catch(error => {
          this.$dgToast.show({
            type: "info",
            title: error,
            content: "Error submiting data, please try again.",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mcp-choose-industries {
  width: 100%;
  overflow: hidden;
  height: 100vh;

  .mcp-add-all {
    font-weight: bold;
    font-size: 12px;
    color: #5bc88d;
  }

  .mcp-count-checked {
    margin-left: 10px;
    color: #768b96;
    font-weight: bold;

    i.dgi {
      position: relative;
      top: 4px;
      color: #5bc88d;
      margin-right: 0;
      font-size: 16px;
    }
  }

  .sub-industries-list {
    list-style: none;
    padding: 0;

    li {
      .sub-industries-item {
        display: flex;
        justify-content: space-between;
        padding: 6px 8px;
        border-bottom: 1px solid #dcdcdcdc;

        span {
          font-weight: bold;
        }

        &:hover {
          cursor: default;
        }
      }

      ul {
        list-style: none;
        padding-left: 20px;
        background-color: #f7f8f9;
        border-bottom: 1px solid #dcdcdcdc;

        li {
          padding: 6px 8px;
          border-bottom: 1px solid #dcdcdcdc;
          display: flex;
          justify-content: space-between;

          &:last-of-type {
            border-bottom: none;
          }

          &:hover {
            cursor: default;
          }
        }
      }
    }
  }

  .mcp-partials {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .mcp-ci-wrapper {
      width: 100%;
      padding: 10px 0;
      overflow-y: overlay;

      &.mcp-ci-shadow {
        margin-right: 15px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
        padding: 10px 20px 0 0;
        height: calc(100vh - 220px);
      }

      .dg-mcp-accordion {
        height: calc(100vh - 285px);
        overflow-y: overlay;
        margin: -20px;
        padding: 0 20px;
        margin-top: 10px;
        padding-bottom: 10px;
      }
    }

    .mcp-basket {
      max-width: 420px;
      width: 100%;
      height: calc(100vh - 220px);
      overflow-y: overlay;
    }

    .dg-scrollbar {
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c3c3c3;
        cursor: pointer;

        &:hover {
          background-color: darken(#c3c3c3, 15%);
        }
      }
    }
  }

  .dg-bottom-bar {
    left: 0;
  }

  .dg-mcp-modal {
    left: 0;
    z-index: 1;
  }
}
</style>
